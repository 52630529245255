<template>
  <v-container fluid>
    <v-row justify="center" v-if="saving">
      <v-progress-circular indeterminate />
    </v-row>
    <qr-codes
      :ownerId="ownerId"
      :qrCodes="qrCodes"
      :isLoading="isLoading"
      @add="onAdd"
      @update="onUpdate"
      @delete="onDelete"
      v-else
    ></qr-codes>
  </v-container>
</template>

<script>
import { getQRCodes, addQRCode, updateQRCode, deleteQRCode } from '../helpers/firebase-functions'
import qrCodes from '../components/qrcode/QRCodes'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    qrCodes
  },
  data: () => ({
    ownerId: '',
    scenarioId: '',
    qrCodeType: 'jobsite',
    qrCodes: [],
    isLoading: false,
    saving: false
  }),
  methods: {
    async getActiveQRCodes ({ ownerId, scenarioId, qrCodeType }) {
      let qrCodes = await getQRCodes({ ownerId, scenarioId, qrCodeType })
      qrCodes = qrCodes.filter(qrCode => qrCode.archived ? !qrCode.archived : true)
      return qrCodes
    },
    async onAdd (code) {
      this.saving = true
      await addQRCode({ ownerId: this.ownerId, scenarioId: this.scenarioId, qrCode: code })
      const qrCodes = await this.getActiveQRCodes({
        ownerId: this.ownerId,
        scenarioId: this.scenarioId,
        qrCodeType: this.qrCodeType
      })
      this.qrCodes = qrCodes
      this.saving = false
    },
    async onUpdate (code) {
      this.saving = true
      await updateQRCode({ ownerId: this.ownerId, scenarioId: this.scenarioId, qrCode: code })
      const qrCodes = await this.getActiveQRCodes({
        ownerId: this.ownerId,
        scenarioId: this.scenarioId,
        qrCodeType: this.qrCodeType })
      this.qrCodes = qrCodes
      this.saving = false
    },
    async onDelete (code) {
      this.saving = true
      await deleteQRCode({ ownerId: this.ownerId, scenarioId: this.scenarioId, qrCode: code })
      const qrCodes = await this.getActiveQRCodes({
        ownerId: this.ownerId,
        scenarioId: this.scenarioId,
        qrCodeType: this.qrCodeType })
      this.qrCodes = qrCodes
      this.saving = false
    }
  },
  async created () {
    this.isLoading = true
    this.ownerId = this.$route.params.ownerId
    this.scenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    const qrCodes = await this.getActiveQRCodes({
      ownerId: this.ownerId,
      scenarioId: this.scenarioId,
      qrCodeType: this.qrCodeType })
    this.qrCodes = qrCodes
    this.isLoading = false
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_QR_CODES)
  }
}
</script>
