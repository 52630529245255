<template>
  <v-card>
    <v-card-title>{{$t('m.media.title')}}</v-card-title>
    <v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="createQRCode">{{$t('m.media.create')}}</v-btn>
      </v-card-actions>
      <v-data-table :headers="headers" :items="qrCodes_" :loading="isLoading">
        <template v-slot:[`item.action`]="{ item }">
          <v-icon class="mr-2" @click.stop="updateQRCode(item)">mdi-pencil</v-icon>
          <v-icon class="ml-2" @click.stop="deleteQRCode(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="createDialog" width="330">
      <qr-code
        :title="$t('m.media.createDialog.title')"
        :qrCodeLabel="qrCodeLabel"
        :qrCodeURL="qrCodeURL"
        @qrCodeLabelChanged="onQRCodeLabelChanged"
        @cancel="onCreateCancel"
        @save="onCreateSave"
      />
    </v-dialog>
    <v-dialog v-model="updateDialog" width="330">
      <qr-code
        mode="update"
        :title="$t('m.media.updateDialog.title')"
        :qrCodeLabel="qrCodeLabel"
        :qrCodeURL="qrCodeURL"
        @qrCodeLabelChanged="onQRCodeLabelChanged"
        @cancel="onUpdateCancel"
        @save="onUpdateSave"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="330">
      <confirm
        :text="$t('m.media.deleteDialog.text', { label: qrCodeLabel })"
        @cancelClicked="onDeleteCancel"
        @okClicked="onDeleteOK"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import { encodeCampaignInfo } from '@/helpers/campaign-info'
import { shrinkUrl } from '@/helpers/referral'
// import { addQRCode } from '@/helpers/firebase-functions'
import qrCode from '@/components/qrcode/QRCode'
import confirm from '@/components/alert/confirm'

export default {
  name: 'qr-codes',
  components: {
    qrCode,
    confirm
  },
  props: {
    ownerId: {
      type: String,
      required: true
    },
    qrCodes: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    qrCodes_: {
      get () {
        return this.qrCodes.map(code => {
          return {
            ...code,
            createdAt: format(new Date(code.createdAt), 'yyyy/MM/dd HH:mm')
          }
        })
      }
    }
  },
  data: function () {
    return {
      headers: [
      // {
      //   text: '作成日時',
      //   align: 'start',
      //   sortable: true,
      //   width: 150,
      //   value: 'createdAt'
      // },
        {
          text: this.$i18n.t('m.media.table.label'),
          align: 'start',
          sortable: true,
          width: 100,
          value: 'label'
        },
        {
          text: this.$i18n.t('m.media.table.id'),
          align: 'start',
          sortable: true,
          width: 100,
          value: 'qrCodeId'
        },
        {
          text: this.$i18n.t('m.media.table.url'),
          align: 'start',
          sortable: true,
          width: 150,
          value: 'url'
        },
        // {
        //   text: '応募受付',
        //   align: 'center',
        //   sortable: true,
        //   width: 90,
        //   value: 'enabled'
        // },
        {
          text: this.$i18n.t('m.media.table.action'),
          align: 'center',
          sortable: false,
          width: 100,
          value: 'action'
        }
      ],
      ownerScenarioId: '',
      createDialog: false,
      qrCodeLabel: '',
      qrCodeURL: '',
      qrCode: null,
      generating: false,
      updateDialog: false,
      detail: {
        label: null,
        createdAt: null,
        url: null
      },
      deleteDialog: false
    }
  },
  methods: {
    randomId (length) {
      let id = ''
      const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      const charsLength = chars.length
      for (let i = 0; i < length; i++) {
        id += chars[Math.floor(Math.random() * charsLength)]
      }
      return id
    },
    async generate () {
      const ownerId = this.ownerId
      const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
      this.ownerScenarioId = ownerScenarioId
      this.qrCodeId = this.randomId(20)

      const absoluteUrl = window ? window.location.origin : ''
      const campaignInfo = encodeCampaignInfo({ ownerId, ownerScenarioId, campaignId: this.qrCodeId, campaignType: 'jobsite' })
      this.qrCodeURL = await shrinkUrl(`${absoluteUrl}/jobs/${ownerId}/${ownerScenarioId}?campaignInfo=${campaignInfo}`)
    },
    clearQRCode () {
      this.qrCodeLabel = ''
      this.qrCodeURL = ''
    },
    createQRCode () {
      this.clearQRCode()
      this.generate()
      this.createDialog = true
    },
    updateQRCode (item) {
      // item.enabled = !item.enabled
      delete item.createdAt // createdAt is transformed to a string -- don't return it
      // this.$emit('update', item)
      this.qrCodeId = item.qrCodeId
      this.qrCodeLabel = item.label
      this.qrCodeURL = item.url
      this.updateDialog = true
    },
    onQRCodeLabelChanged (value) {
      this.qrCodeLabel = value
    },
    async onCreateSave () {
      const qrCode = {
        label: this.qrCodeLabel,
        url: this.qrCodeURL,
        enabled: true,
        count: 0,
        qrCodeId: this.qrCodeId,
        qrCodeType: 'jobsite'
      }

      this.$emit('add', qrCode)
      this.createDialog = false
    },
    onCreateCancel () {
      this.clearQRCode()
      this.createDialog = false
    },
    onUpdateSave () {
      const qrCode = {
        label: this.qrCodeLabel,
        url: this.qrCodeURL,
        enabled: true,
        count: 0,
        qrCodeId: this.qrCodeId,
        qrCodeType: 'jobsite'
      }

      this.$emit('update', qrCode)
      this.updateDialog = false
    },
    onUpdateCancel () {
      this.clearQRCode()
      this.updateDialog = false
    },
    deleteQRCode (item) {
      const { label } = item
      this.qrCodeLabel = label
      this.qrCode = item
      this.deleteDialog = true
    },
    onDeleteOK () {
      this.$emit('delete', this.qrCode)
      this.deleteDialog = false
    },
    onDeleteCancel () {
      this.deleteDialog = false
    }
  }
}
</script>
